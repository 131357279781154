import React from "react";
import { useLang } from "../../i18n/useLang";
import SearchPanel from "./searchPanel/search-panel";
import CommentPanel from "./comment/comment-panel";
import ThumbnailPanel from "./thumbnail/thumbnail-panel";
import WatchdogPanel from "./watchdog/watchdog-panel";
import ReplacePanel from "./replace/replace-panel";
import NotePanel from "./note/note-panel";
import TOCPanel from "./TOC/toc-panel";
import BookmarkPanel from "./bookmark/bookmark-panel";
import { ApprovalStampPanel } from "./approvalStamp/ApprovedStampPanel";
import History from "./history/history";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import { DirectionalHint } from "@fluentui/react";
import {
  StyledLeftContainer,
  StyledTabs,
} from "../../assets/style/left-sidebar/leftLayout";
import { useId } from "@fluentui/react-hooks";
import {
  ArrowSwap24Regular,
  Bookmark28Regular,
  ClipboardBulletListLtr20Regular,
  CommentMultiple28Regular,
  History28Regular,
  ImageCopy28Regular,
  Note28Regular,
  Search28Regular,
} from "@fluentui/react-icons";
import { useRecoilState } from "recoil";
import {
  bookMarkService,
  commentService,
  replaceService,
  searchService,
  thumbnailService,
  TOCService,
  WatchDogService,
} from "../../service";
import {
  historyState,
  leftSidebarState,
  noteState,
  pageSelectedState,
  replaceState,
  searchState,
  thumbnailState,
} from "../../recoil";
import { historyService } from "../../service/historyService";
import { noteService } from "../../service/noteService";
import { CheckVisibleElement } from "../../utils/helpers";
import { CacheApp } from "../../App";
import { PageHelper } from "../../utils/pageHelper";
import npApprovalStamp from "../../assets/img/npApprovalStamp.png";
import { approvalStampService } from "../../service/approvalStampService";
export enum TabIndex {
  HistoryTab = 9,
  CommentTab = 8,
  TOCTab = 7,
  ApprovalStamp = 6,
  NoteTab = 5,
  Bookmark = 4,
  WatchdogTab = 3,
  ReplaceTab = 2,
  ThumbnailTab = 1,
  SearchTab = 0,
}
export default () => {
  const { refreshWatchDogList } = WatchDogService();
  const { RefreshReplaceWord } = replaceService();
  const { onSearchWord } = searchService();
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const { getTOCInfo } = TOCService();
  const [thumbnailCurrentState] = useRecoilState(thumbnailState);
  const { refreshThumbnail } = thumbnailService();
  const { getCommentList } = commentService();
  const [leftSidebar, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const [historyCurrentState] = useRecoilState(historyState);
  const [replaceCurrentState] = useRecoilState(replaceState);
  const { getHistoryView } = historyService();
  const { tr } = useLang();
  const { GetListApprovalStamp } = approvalStampService();
  const { getBookMarkList } = bookMarkService();
  const { refreshNoteList } = noteService();
  const styles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };
  const calloutProps = { gapSpace: 0 };
  const tooltipId = useId("tooltip");

  React.useEffect(() => {
    if (leftSidebar.refreshTabActive?.IsRefresh) {
      refreshTabLeftMenu();
    }
  }, [leftSidebar.refreshTabActive]);

  const refreshTabLeftMenu = () => {
    let tab = leftSidebar.lastPanelSelected;
    if (
      leftSidebar.refreshTabActive?.TabIndex &&
      leftSidebar.refreshTabActive?.TabIndex > -1
    ) {
      tab = leftSidebar.refreshTabActive?.TabIndex;
    }
    switch (tab) {
      case TabIndex.CommentTab:
        getCommentList();
        break;
      case TabIndex.HistoryTab:
        getHistoryView(historyCurrentState.tabId);
        break;
      case TabIndex.NoteTab:
        refreshNoteList();
        break;
      case TabIndex.ReplaceTab:
        RefreshReplaceWord(replaceCurrentState);
        break;
      case TabIndex.TOCTab:
        getTOCInfo();
        break;
      case TabIndex.ApprovalStamp:
        GetListApprovalStamp();
        break;
      case TabIndex.Bookmark:
        getBookMarkList();
        break;
      case TabIndex.ThumbnailTab:
        if (
          thumbnailCurrentState.toPage &&
          (thumbnailCurrentState.fromPage || 0) <=
            (thumbnailCurrentState.toPage || 0)
        ) {
          let step = 5;
          const pageNos = GetPagesShow();
          const minpage = Math.min(...pageNos);
          if (pageNos && pageNos.length > 0) {
            step = pageNos.length;
          }
          let fromPage = PageHelper.calculateCurrentpage();
          if (!fromPage) fromPage = thumbnailCurrentState.fromPage;
          const totalPage =
            CacheApp.DocuviewareMain?.DocuviewareInit?.totalPage || 0;
          if (fromPage > 1 && fromPage > totalPage - 3) {
            fromPage -= step;
          }
          const toPage = fromPage + 7;
          if (fromPage > minpage) {
            fromPage = minpage;
          }
          refreshThumbnail(fromPage, toPage);
        }
        break;
      case TabIndex.WatchdogTab:
        if (leftSidebar.lastPanelSelected == tab) {
          refreshWatchDogList(() => {
            setLeftSidebarState((cur) => {
              return {
                ...cur,
                refreshTabActive: { IsRefresh: false, TabIndex: -1 },
              };
            });
          });
        }

        break;
      case TabIndex.SearchTab:
        if (
          searchCurrentState.searchTabVm.autoSearch ||
          leftSidebar.refreshTabActive?.ForceSearch
        ) {
          if ((CacheApp.PageState?.currentPage || 0) <= 0) {
            setSearchState({ ...searchCurrentState, searchResult: {} });
          } else {
            onSearchWord(CacheApp.PageState?.currentPage || 0);
          }
        } else if (leftSidebar.refreshTabActive?.ClearSearch == true) {
          setSearchState({ ...searchCurrentState, searchResult: {} });
        }
        break;
    }
    setLeftSidebarState((cur) => {
      return { ...cur, refreshTabActive: { IsRefresh: false, TabIndex: -1 } };
    });
  };

  const setTab = (tabIndex: number) => {
    setLeftSidebarState((cur) => {
      const state = JSON.parse(JSON.stringify(cur));
      state.lastPanelSelected = tabIndex;
      if (state.SecondaryInitialSize <= 100) {
        state.SecondaryInitialSize = 425;
      }
      return state;
    });
  };
  const GetPagesShow = (): number[] => {
    const pageNos: number[] = [];
    const container = document.getElementById(`thumbnailSelectionContainer`);
    CacheApp.Thumbnails.forEach((tab) => {
      if (tab.imageBases) {
        tab.imageBases.forEach((item) => {
          const el = document.getElementById("thumbnail-" + item.pageNo);
          if (CheckVisibleElement(el, container || undefined)) {
            if (item.pageNo) {
              pageNos.push(item.pageNo);
            }
          }
        });
      }
    });
    return pageNos;
  };
    console.log("left-sidebar-container.tsx", CacheApp.PageState?.currentPage);
  return (
    <StyledLeftContainer>
      <StyledTabs
        disable={((CacheApp.PageState?.currentPage || 0) > 0
          ? 0
          : 1
        ).toString()}
      >
        <Tabs
          id="tabMain"
          onSelect={() => { }}
          selectedIndex={leftSidebar.lastPanelSelected}
        >
          <TabList>
            <Tab disabled onClick={() => setTab(TabIndex.SearchTab)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("find")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <Search28Regular aria-describedby={tooltipId}></Search28Regular>
              </TooltipHost>
            </Tab>
            <Tab disabled onClick={() => setTab(TabIndex.ThumbnailTab)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("thumbnail")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <ImageCopy28Regular
                  aria-describedby={tooltipId}
                ></ImageCopy28Regular>
              </TooltipHost>
            </Tab>
            <Tab onClick={() => setTab(TabIndex.ReplaceTab)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("replace")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <ArrowSwap24Regular
                  aria-describedby={tooltipId}
                ></ArrowSwap24Regular>
              </TooltipHost>
            </Tab>
            <Tab onClick={() => setTab(TabIndex.WatchdogTab)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("watchdog")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <img width={18} height={18} src="/img/watchdog.svg"></img>
              </TooltipHost>
            </Tab>
            <Tab onClick={() => setTab(TabIndex.Bookmark)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("Bookmark")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <Bookmark28Regular
                  aria-describedby={tooltipId}
                ></Bookmark28Regular>
              </TooltipHost>
            </Tab>
            <Tab onClick={() => setTab(TabIndex.NoteTab)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("note")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <Note28Regular aria-describedby={tooltipId}></Note28Regular>
              </TooltipHost>
            </Tab>
            <Tab onClick={() => setTab(TabIndex.ApprovalStamp)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("approvalStamp")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <img
                  width={18}
                  style={{ marginLeft: 4 }}
                  src={npApprovalStamp}
                  alt=""
                  aria-describedby={tooltipId}
                />
              </TooltipHost>
            </Tab>
            <Tab onClick={() => setTab(TabIndex.TOCTab)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("toc")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <ClipboardBulletListLtr20Regular
                  aria-describedby={tooltipId}
                ></ClipboardBulletListLtr20Regular>
              </TooltipHost>
            </Tab>
            <Tab onClick={() => setTab(TabIndex.CommentTab)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("comment")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <CommentMultiple28Regular
                  aria-describedby={tooltipId}
                ></CommentMultiple28Regular>
              </TooltipHost>
            </Tab>
            <Tab onClick={() => setTab(TabIndex.HistoryTab)}>
              <div className="line"></div>
              <TooltipHost
                directionalHint={DirectionalHint.rightCenter}
                content={tr("Log")}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styles}
              >
                <History28Regular
                  aria-describedby={tooltipId}
                ></History28Regular>
              </TooltipHost>
            </Tab>
          </TabList>
          <TabPanel disabled={true} style={{ overflowY: "auto" }}>
            {leftSidebar.lastPanelSelected === TabIndex.SearchTab && (
              <SearchPanel />
            )}
          </TabPanel>
          <TabPanel>
            <ThumbnailPanel
              Selected={leftSidebar.lastPanelSelected === TabIndex.ThumbnailTab}
            />
          </TabPanel>
          <TabPanel>
            {leftSidebar.lastPanelSelected === TabIndex.ReplaceTab && (
              <ReplacePanel />
            )}
          </TabPanel>
          <TabPanel>
            {leftSidebar.lastPanelSelected === TabIndex.WatchdogTab && (
              <WatchdogPanel />
            )}
          </TabPanel>
          <TabPanel>
            {leftSidebar.lastPanelSelected === TabIndex.Bookmark && (
              <BookmarkPanel />
            )}
          </TabPanel>
          <TabPanel>
            {leftSidebar.lastPanelSelected === TabIndex.NoteTab && (
              <NotePanel />
            )}
          </TabPanel>
          <TabPanel>
            {leftSidebar.lastPanelSelected === TabIndex.ApprovalStamp && (
              <ApprovalStampPanel />
            )}
          </TabPanel>
          <TabPanel>
            {leftSidebar.lastPanelSelected === TabIndex.TOCTab && <TOCPanel />}
          </TabPanel>
          <TabPanel>
            {leftSidebar.lastPanelSelected === TabIndex.CommentTab && (
              <CommentPanel ExpandAllContent={true} />
            )}
          </TabPanel>
          <TabPanel>
            {leftSidebar.lastPanelSelected === TabIndex.HistoryTab && (
              <History />
            )}
          </TabPanel>
        </Tabs>
      </StyledTabs>
    </StyledLeftContainer>
  );
};
